// ! this is potentially unsafe
// the JS object might include keys/values not defined by its TS type
// Object methods would in such case be typed incorrectly/incompletely
// eg. https://github.com/microsoft/TypeScript/issues/43266#issuecomment-800878780

// for this reason, its intentional to declare this strictly typed logic as separate functions
// rather than re-typing build-in Object methods
// -> the dev using those will do it intentionally and it’s more likely pitfalls will be known
// it can still lead to uncaught bugs but it’s the same like re-typing the key/entry using `as` keyword

// also the type behavior is inconsistent
// while KEYS are considered UNSAFE (to be typed strictly) the VALUES are strictly typed right now
// there is a lot of discussion about DX vs. safeness in TS repo
// and it was changed back and forward multiple times
// here is one of the main discussions https://github.com/microsoft/TypeScript/issues/38520

/**
 * Object.entries but typed more strictly
 * (according to the type of provided object)
 * consider described pitfalls when using this!
 */
export const objectEntries = <TObject extends Record<string, unknown>>(
  object: TObject,
) => {
  return Object.entries(object) as Entries<TObject>
}

/**
 * Object.keys but typed more strictly
 * (according to the type of provided object)
 * consider described pitfalls when using this!
 */
export const objectKeys = <TObject extends Record<string, unknown>>(
  object: TObject,
) => {
  return Object.keys(object) as unknown as (keyof TObject)[]
}

// keeping values here for consistency, even though those are strictly typed by default right now

/**
 * Object.values but typed more strictly
 * (according to the type of provided object)
 * consider described pitfalls when using this!
 */
export const objectValues = Object.values
