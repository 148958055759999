import Color from 'color'

import { clampValue } from './clampValue'

export type TColorShade = 'light' | 'dark'

const normalizeValue = clampValue({ min: 0, max: 1 })

/**
 * Decides what color shade (dark/light) would have a better contrast with provided color.
 * Typically used for deducing text color from the background
 */
export const getContrastShadeFor = (color: string): TColorShade => {
  if (color === `rgba(0,0,0,0)` || color === `inherit` || color === `transparent`) {
    return 'dark'
  }

  // return Color(color).isDark() ? 'light' : 'dark'
  return Color(color).luminosity() < 0.4 ? 'light' : 'dark'
}

const GREYISH_COLOR_MAX_SATURATION = 15

/**
 * Decides if the color is kind of grey or colorful
 */
export const getIsGreyishColor = (color: string): boolean => {
  if (color === `rgba(0,0,0,0)` || color === `inherit` || color === `transparent`) {
    return false
  }

  return Color(color).saturationv() < GREYISH_COLOR_MAX_SATURATION
}

/**
 * Returns the opposite color shade (dark/light) than the one you passed in
 */
export const reverseShade = (shade: TColorShade): TColorShade => {
  return shade === 'dark' ? 'light' : 'dark'
}

/**
 * Make the provided color (more) opaque
 * @param inputColor the base color
 * @param inputOpacity the required alpha
 */
export const makeTransparent = (inputColor: string, inputOpacity: number = 1) => {
  const opacity = normalizeValue(inputOpacity)

  return Color(inputColor).alpha(opacity).rgb().string()
}

/**
 * Make the provided color more light
 * @param inputColor
 * @param inputLight
 */
export const lighten = (inputColor: string, inputLight: number = 0) => {
  return Color(inputColor).lighten(inputLight).rgb().string()
}

const CHANNEL_WHITE = 255

/**
 * Make the provided color more white (take that, Coca-cola)
 * @param inputColor
 * @param inputLight
 */
export const whiten = (inputColor: string, inputLight: number = 0) => {
  const color = Color(inputColor)
  const light = normalizeValue(inputLight)

  // newRed = oldRed + light * (255 - oldRed)
  const newColorChannels = color
    .rgb()
    .array()
    .map((originalColorChannel) => {
      return originalColorChannel + light * (CHANNEL_WHITE - originalColorChannel)
    })

  return Color.rgb(newColorChannels).rgb().string()
}

/**
 * Returns `true` if the `colorValue` starts with "#",
 * indicating it is hex color code
 */
export const isHexColor = (colorValue: Nullable<string>): colorValue is string => {
  return Boolean(colorValue?.startsWith('#'))
}

/**
 * Returns the `colorValue` in the rgb string format
 *
 * @example `rgb(232, 108, 89)`
 */
export const convertToRgb = (colorValue: string) => Color(colorValue).rgb().string()

/**
 * Returns the `colorValue` in the hsl string format
 *
 * @example `hsl(240, 50%, 96.1%)`
 */
export const convertToHsl = (colorValue: string) => Color(colorValue).hsl().string()

/**
 * Returns the `colorValue` in the hex format
 *
 * @example `#E86C59`
 */
export const convertToHex = (colorValue: string) => Color(colorValue).hex()
