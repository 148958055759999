import * as React from 'react'

import {
  getContrastShadeFor,
  TColorShade,
} from '../utils/colorUtils'
import { createContext } from '../utils/createContext'

type TContextValue = {
  fgColorShade: TColorShade
}

const [Provider, useBgColorCtx] = createContext<TContextValue>(`BgColor`)

type TProps = RequiredChildren & {
  bgColor: string
}

export const BgColorProvider: React.FC<TProps> = ({ children, bgColor }) => {
  const fgColorShade = getContrastShadeFor(bgColor)

  return <Provider value={{ fgColorShade }}>{children}</Provider>
}

export { useBgColorCtx }
