type TChangeOptions = {
  illustration: string
  containerId: string
}

export const changeIllustration = ({ containerId, illustration }: TChangeOptions) => {
  const container = document.querySelector(`#${containerId}`)

  if (!container) {
    return
  }

  if (container.firstChild) {
    container.removeChild(container.firstChild)
  }

  container.innerHTML = illustration
}
