import * as React from 'react'

import {
  ILLUSTRATION_SIZES,
  useSizeCtx,
} from '../contexts/SizeProvider'
import styles from './IllustrationContainer.module.css'

type TProps = {
  id: string
}

export const IllustrationContainer: React.FC<TProps> = ({ id }) => {
  const { selectedSize } = useSizeCtx()

  return (
    <div
      id={id}
      className={styles.container}
      style={{ width: ILLUSTRATION_SIZES[selectedSize], height: ILLUSTRATION_SIZES[selectedSize] }}
    />
  )
}
