export const CONFIG = {
  DARK_BOARD_ID: `darkBoard`,
  LIGHT_BOARD_ID: `lightBoard`,

  DARK_BOARD_INIT_COLOR: `hsl(0, 0%, 13.7%)`,
  LIGHT_BOARD_INIT_COLOR: `hsl(0, 0%, 94.1%)`,

  PRIMARY_COLOR_INIT_VALUE: `rgb(232, 108, 89)`,
  PRIMARY_COLOR_KEY: `theme.specialColors.illustration`,
  PRIMARY_COLOR_HEX: `#FF725E`, // usually used in original illustrations
}
