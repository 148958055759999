import * as React from 'react'

import { BoardDisplayControls } from './BoardDisplayControls'
import { ColorControls } from './ColorControls'
import styles from './Controls.module.css'
import { HistoryControls } from './HistoryControls'
import { IllustrationControls } from './IllustrationControls'
import { PathControls } from './PathControls'
import { SizeControls } from './SizeControls'

type TProps = NoChildren

export const Controls: React.FC<TProps> = () => {
  return (
    <div className={styles.container}>
      <IllustrationControls />
      <BoardDisplayControls />
      <SizeControls />
      <PathControls />
      <HistoryControls />
      <ColorControls />
    </div>
  )
}
