import * as React from 'react'

import { getDarkSvgPaths, getLightSvgPaths } from '../../utils/getSvgPaths'
import { markPath, unmarkPath } from '../../utils/markPath'

type TGetIndex = (prevIndex: Nullable<number>) => Nullable<number>

/**
 * Handles the `selectedPathIndex` state
 *
 * Returns the `selectedPathIndex` and the `setPathIndex` function that:
 *  - marks and unmarks old and new selected path respectively
 *  - accepts either the new path `index` itself or a function to compute the new index using the `prevSelectedIndex`
 *
 * also returns the `pathChangeCount` to be able to reset the selected color
 * after applying a new history image
 */
export const useSelectedPath = () => {
  const [selectedPathIndex, setSelectedPathIndex] = React.useState<Nullable<number>>(null)
  const [pathChangeCount, setPathChangeCount] = React.useState(0)

  const setPathIndex = React.useCallback((index: Nullable<number> | TGetIndex) => {
    setSelectedPathIndex((prevSelectedIndex) => {
      const newIndex = typeof index === 'function' ? index(prevSelectedIndex) : index

      markPathByIndex({ oldIndex: prevSelectedIndex, newIndex })

      return newIndex
    })

    setPathChangeCount((prevCount) => prevCount + 1)
  }, [])

  return { selectedPathIndex, setPathIndex, pathChangeCount }
}

// * HELPERS

type TMarkPathOptions = {
  oldIndex: Nullable<number>
  newIndex: Nullable<number>
}

// marks the path at the `newIndex` & unmarks the path at the `oldIndex`
const markPathByIndex = ({ oldIndex, newIndex }: TMarkPathOptions) => {
  const lightSvgPaths = getLightSvgPaths()
  const darkSvgPaths = getDarkSvgPaths()

  if (oldIndex !== null) {
    const oldLightPath = lightSvgPaths?.at(oldIndex)
    const oldDarkPath = darkSvgPaths?.at(oldIndex)

    unmarkPath(oldLightPath)
    unmarkPath(oldDarkPath)
  }

  if (newIndex !== null) {
    const newLightPath = lightSvgPaths?.at(newIndex)
    const newDarkPath = darkSvgPaths?.at(newIndex)

    markPath(newLightPath)
    markPath(newDarkPath)
  }
}
