import * as React from 'react'

import {
  ILLUSTRATION_SIZES,
  useSizeCtx,
} from '../contexts/SizeProvider'
import { objectKeys } from '../utils/object'
import { Button } from './Button'
import { ControlGroup } from './ControlGroup'

type TProps = NoChildren

export const SizeControls: React.FC<TProps> = () => {
  const { selectedSize, setSelectedSize } = useSizeCtx()

  return (
    <ControlGroup title={`Size`}>
      {objectKeys(ILLUSTRATION_SIZES).map((size) => (
        <Button key={size} onClick={() => setSelectedSize(size)} selected={size === selectedSize}>
          {size}
        </Button>
      ))}
    </ControlGroup>
  )
}
