import './App.css'

import * as React from 'react'

import { Toaster } from 'react-hot-toast'

import { Controls } from './components/Controls'
import { IllustrationBoard } from './components/IllustrationBoard'
import { BoardDisplayProvider } from './contexts/BoardDisplayProvider'
import { HistoryProvider } from './contexts/HistoryProvider'
import { PrimaryColorProvider } from './contexts/PrimaryColorProvider'
import { SelectedColorProvider } from './contexts/SelectedColorProvider'
import { SelectedElementProvider } from './contexts/SelectedElementProvider/SelectedElementProvider'
import { SizeProvider } from './contexts/SizeProvider'

export const App: React.FC = () => {
  return (
    <SizeProvider>
      <Toaster />
      <BoardDisplayProvider>
        <PrimaryColorProvider>
          <SelectedElementProvider>
            <HistoryProvider>
              <SelectedColorProvider>
                <div className="app">
                  <Controls />
                  <IllustrationBoard />
                </div>
              </SelectedColorProvider>
            </HistoryProvider>
          </SelectedElementProvider>
        </PrimaryColorProvider>
      </BoardDisplayProvider>
    </SizeProvider>
  )
}
