import * as React from 'react'

import { CONFIG } from '../config/config'
import { changeIllustration } from '../utils/changeIllustration'
import { createContext } from '../utils/createContext'
import { usePrimaryColorCtx } from './PrimaryColorProvider'
import {
  useReconnectCtx,
  useUpdateSelectedElementCtx,
} from './SelectedElementProvider/SelectedElementProvider'

type TContextValue = {
  undo: () => void
  redo: () => void
  storeCurrentImage: () => void
  clearHistory: () => void
}

const [
  Provider, //
  useHistoryCtx,
] = createContext<TContextValue>(`History`)

const DEBOUNCE_DELAY = 250 // ms

type TProps = RequiredChildren

/**
 * Provides handlers to manipulate the history of changes:
 *  - `storeCurrentImage` to add new changes to the history array
 *  - `clearHistory` to reset the history for the newly imported illustration
 *  - `undo` to apply the previous history image
 *  - `redo` to move back to the more recent image
 */
export const HistoryProvider: React.FC<TProps> = ({ children }) => {
  const refCurrentIndex = React.useRef<Nullable<number>>(null)
  const refTimeoutId = React.useRef<NodeJS.Timeout>()

  const { setPathIndex } = useUpdateSelectedElementCtx()

  const [history, setHistory] = React.useState<RoA<string>>([])

  const { changePrimaryColor } = usePrimaryColorCtx()
  const reconnectHandlers = useReconnectCtx()

  const applyHistoryImage = React.useCallback(
    (image: string) => {
      changeIllustration({
        illustration: image,
        containerId: CONFIG.LIGHT_BOARD_ID,
      })

      changeIllustration({
        illustration: image,
        containerId: CONFIG.DARK_BOARD_ID,
      })

      // use the primary color of the history image
      const imagePrimaryColorValue = findPrimaryColorValue(image)

      if (imagePrimaryColorValue) {
        changePrimaryColor(imagePrimaryColorValue)
      }

      // to (re)mark the path & get selected color of the applied image
      setPathIndex((selectedIndex) => selectedIndex)

      reconnectHandlers()
    },
    [changePrimaryColor, reconnectHandlers, setPathIndex],
  )

  const undo = React.useCallback(() => {
    // will return for both null & 0
    if (!refCurrentIndex.current) return

    const prevImage = history.at(refCurrentIndex.current - 1)

    if (!prevImage) return

    applyHistoryImage(prevImage)
    refCurrentIndex.current--
  }, [applyHistoryImage, history])

  const redo = React.useCallback(() => {
    if (refCurrentIndex.current === null) return

    const nextImage = history.at(refCurrentIndex.current + 1)

    if (!nextImage) return

    applyHistoryImage(nextImage)
    refCurrentIndex.current++
  }, [applyHistoryImage, history])

  // add to the end of the history array
  // replace possible subsequent images (relatively to the current index)
  const storeCurrentImage = () => {
    // debounced function,
    // the code wrapped in the `setTimeout` below is executed only once in the DEBOUNCE_DELAY interval
    clearTimeout(refTimeoutId.current)

    refTimeoutId.current = setTimeout(() => {
      const container = document.querySelector(`#${CONFIG.LIGHT_BOARD_ID}`)

      if (!container?.firstElementChild) return

      const svgElement = container.firstElementChild

      if (svgElement instanceof Element) {
        const image = svgElement.outerHTML
        // unmark selected path
        const unmarkedImage = image.replace(`stroke="blue" stroke-width="0.5"`, ``)

        setHistory((prevHistory) => {
          const historySliceEnd =
            refCurrentIndex.current !== null //
              ? refCurrentIndex.current + 1
              : undefined

          // get the history prior to the current index (current image included)
          const history =
            prevHistory.length > 0 //
              ? prevHistory.slice(0, historySliceEnd)
              : prevHistory

          // add the new image
          return [...history, unmarkedImage]
        })

        // increase the current index
        refCurrentIndex.current = refCurrentIndex.current !== null ? refCurrentIndex.current + 1 : 0
      }
    }, DEBOUNCE_DELAY)
  }

  const clearHistory = () => {
    refCurrentIndex.current = null
    setHistory([])
  }
  // support ctrl/cmd + z & ctrl/cmd + shift + z keyboard shortcuts
  const onKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      event.stopImmediatePropagation()

      const isCmdOrCtrlZ = event.key.toLowerCase() === 'z' && (event.ctrlKey || event.metaKey)

      if (!isCmdOrCtrlZ) return

      if (event.shiftKey) {
        redo()
        return
      }

      undo()
    },
    [redo, undo],
  )

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyPress)

    return () => document.removeEventListener('keydown', onKeyPress)
  }, [onKeyPress])

  return <Provider value={{ undo, redo, storeCurrentImage, clearHistory }}>{children}</Provider>
}

export { useHistoryCtx }

// * HELPERS

const RGB_REGEX = /rgb[\s]?[(]?[\s+]?\d+[(\s)|(,)]+[\s+]?\d+[(\s)|(,)]+[\s+]?\d+[(\s)|(,)]+[\s+]?/

// gets the first rgb value from the provided `image`
// there should be only one rbg color value,
// as the primary color is the only color defined in rgb format
const findPrimaryColorValue = (image: string) => {
  const imagePrimaryColorMatches = image.match(RGB_REGEX)
  return imagePrimaryColorMatches ? imagePrimaryColorMatches[0] : null
}
