import * as React from 'react'

import toast from 'react-hot-toast'

type TOptions = {
  text?: string
}

export const useCopyToClip = (hookOptions?: TOptions) => {
  const copyToClipboard = React.useCallback(
    (cbOptions?: TOptions) => {
      const text = cbOptions?.text ?? hookOptions?.text

      if (!text) {
        toast.error(`Calling 'copyToClipboard' with noting to copy!`)
        return
      }

      navigator.clipboard.writeText(text)
      toast.success(`Copied to clipboard`)
    },
    [hookOptions?.text],
  )

  return copyToClipboard
}
