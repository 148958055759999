import { CONFIG } from '../config/config'

/**
 * Returns all non-text child nodes of the svg element in the light board
 */
export const getLightSvgPaths = () => getSvgPathsInContainer(CONFIG.LIGHT_BOARD_ID)

/**
 * Returns all non-text child nodes of the svg element in the dark board
 */
export const getDarkSvgPaths = () => getSvgPathsInContainer(CONFIG.DARK_BOARD_ID)

// * HELPERS

// returns `true` for the non-text `node`
const isNotTextNode = (node: ChildNode) => node.nodeName !== `#text`

// gets non-text child nodes of the svg element
// in the container identified by the `containerId`
const getSvgPathsInContainer = (containerId: string) => {
  const container = document.querySelector(`#${containerId}`)

  if (!container?.firstElementChild) return

  const svgElement = container.firstElementChild

  const svgPaths = [...svgElement.childNodes].filter(isNotTextNode).flatMap((element) => {
    if (element.nodeName === `g`) {
      return [...element.childNodes].filter(isNotTextNode)
    }

    return element
  })

  return svgPaths
}
