import * as React from 'react'

import { changeIllustration } from '../utils/changeIllustration'
import { TColorShade } from '../utils/colorUtils'
import { createContext } from '../utils/createContext'
import { swapColorShade } from '../utils/replaceColors'
import { useBgColorCtx } from './BgColorProvider'
import { useReconnectCtx } from './SelectedElementProvider/SelectedElementProvider'

type TContext = TIllustrationColors

const [Provider, useIllustrationColorsCtx] = createContext<TContext>(`IllustrationColors`)

type TProps = RequiredChildren & {
  containerId: string
}

export const IllustrationColorsProvider: React.FC<TProps> = ({ children, containerId }) => {
  const { fgColorShade } = useBgColorCtx()
  const reconnectHandlers = useReconnectCtx()

  React.useEffect(() => {
    const container = document.querySelector(`#${containerId}`)

    if (!container?.firstElementChild) return

    const svgElement = container.firstElementChild

    if (svgElement instanceof Element) {
      const bgColorShade: TColorShade = fgColorShade === 'light' ? 'dark' : 'light'
      const oldShadeIllustration = svgElement.outerHTML
      const newShadeIllustration = swapColorShade(oldShadeIllustration, bgColorShade)

      changeIllustration({ illustration: newShadeIllustration, containerId })
      reconnectHandlers()
    }
  }, [containerId, fgColorShade, reconnectHandlers])

  // the provider is no longer used anywhere
  return (
    <Provider value={fgColorShade === `dark` ? DARK_COLORS : LIGHT_COLORS}>{children}</Provider>
  )
}

export { useIllustrationColorsCtx }

export type TIllustrationColors = {
  g000: string
  g100: string
  g200: string
  g300: string
  g400: string
  g500: string
  g600: string
  g700: string
  g800: string
  g900: string
}

export const DARK_COLORS: TIllustrationColors = {
  g000: `hsl(0, 0%, 1%)`,
  g100: `hsl(0, 2%, 11%)`,
  g200: `hsl(0, 1%, 17%)`, // fake
  g300: `hsl(0, 0%, 22%)`,
  g400: `hsl(0, 0%, 28%)`, // fake
  g500: `hsl(0, 0%, 36%)`,
  g600: `hsl(0, 0%, 42%)`, // fake
  g700: `hsl(0, 0%, 55%)`, // fake
  g800: `hsl(0, 0%, 74%)`, // fake
  g900: `hsl(0, 0%, 87%)`,
}

export const LIGHT_COLORS: TIllustrationColors = {
  g000: `hsl(0, 0%, 98%)`, // fake
  g100: `hsl(0, 0%, 89%)`,
  g200: `hsl(0, 1%, 82%)`,
  g300: `hsl(0, 0%, 79%)`, // fake
  g400: `hsl(0, 0%, 75%)`,
  g500: `hsl(0, 0%, 63%)`, // fake
  g600: `hsl(0, 0%, 51%)`, // fake
  g700: `hsl(0, 0%, 38%)`, // fake
  g800: `hsl(199, 18%, 33%)`,
  g900: `hsl(200, 19%, 18%)`,
}
