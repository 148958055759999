import * as React from 'react'

import { createContext } from '../utils/createContext'

type TIllustrationSize = keyof typeof ILLUSTRATION_SIZES

type TContext = {
  selectedSize: TIllustrationSize
  setSelectedSize: (size: TIllustrationSize) => void
}

const [Provider, useSizeCtx] = createContext<TContext>(`Size`)

type TProps = RequiredChildren

export const SizeProvider: React.FC<TProps> = ({ children }) => {
  const [selectedSize, setSelectedSize] = React.useState<TIllustrationSize>(`full`)

  return <Provider value={{ selectedSize, setSelectedSize }}>{children}</Provider>
}

export { useSizeCtx }

export const ILLUSTRATION_SIZES = {
  extraSmall: `90px`,
  small: `120px`,
  medium: `200px`,
  large: `280px`,
  display: `460px`,
  full: `100%`,
} as const
