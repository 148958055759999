import * as React from 'react'

import { clampValue } from '../../utils/clampValue'
import { useSelectedPath } from './useSelectedPath'

type TSetPathIndex = ReturnType<typeof useSelectedPath>['setPathIndex']

const NO_PATH_SELECTED = -1

type TOptions = {
  pathsCount: number
  setPathIndex: TSetPathIndex
}

/**
 * Manages different ways to change the selected path:
 *
 *  - adds the `keyUp` event listener to change the selected path by left & right arrow keys
 *  - returns the `changeSelectedPath` function to change the selected path by passing the `newIndex`
 */
export const useChangeSelectedPath = ({ setPathIndex, pathsCount }: TOptions) => {
  // clamp the path index to not overflow the range of the current paths
  const clampPathIndex = React.useCallback(
    (index: Nullable<number>) => {
      const clampedIndex = clampValue({ min: NO_PATH_SELECTED, max: pathsCount - 1 })(index)
      return clampedIndex === NO_PATH_SELECTED ? null : clampedIndex
    },
    [pathsCount],
  )

  // allows to change the selected path by left & right arrow keys
  const onKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        // calculate the new index based on the previous index & the pressed key
        const getNewIndex: FirstParam<TSetPathIndex> = (prevSelectedIndex) => {
          const prevIndex = prevSelectedIndex ?? NO_PATH_SELECTED
          const newIndex = event.key === 'ArrowLeft' ? prevIndex - 1 : prevIndex + 1

          return clampPathIndex(newIndex)
        }

        setPathIndex(getNewIndex)
      }
    },
    [clampPathIndex, setPathIndex],
  )

  React.useEffect(() => {
    document.addEventListener(`keyup`, onKeyPress)

    return () => {
      document.removeEventListener(`keyup`, onKeyPress)
    }
  }, [onKeyPress])

  // allows to change the selected path by passing the `newIndex`
  const changeSelectedPath = React.useCallback(
    (newIndex: Nullable<number>) => {
      const clampedIndex = clampPathIndex(newIndex)

      setPathIndex(clampedIndex)
    },
    [clampPathIndex, setPathIndex],
  )

  return changeSelectedPath
}
