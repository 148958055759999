import { CONFIG } from '../config/config'
import {
  DARK_COLORS,
  LIGHT_COLORS,
  TIllustrationColors,
} from '../contexts/IllustrationColorsProvider'
import { TColorShade } from './colorUtils'
import { objectEntries } from './object'

export const replaceColorsWithNames = (origin: string, colors: TIllustrationColors) => {
  const result = objectEntries(colors).reduce((result, [name, value]) => {
    return result.split(`fill="${value}"`).join(`fill={colors.${name}}`)
  }, origin)

  return result
}

export const replaceNamesWithColors = (origin: string, colors: TIllustrationColors) => {
  const result = objectEntries(colors).reduce((result, [name, value]) => {
    return result.split(`fill={colors.${name}}`).join(`fill="${value}"`)
  }, origin)

  return result
}

export const replacePrimaryColorValue = (origin: string, primaryColorValue: string) => {
  return origin.split(`fill="${primaryColorValue}"`).join(`fill={${CONFIG.PRIMARY_COLOR_KEY}}`)
}

export const replacePrimaryColorName = (origin: string, primaryColorValue: string) => {
  return origin.split(`fill={${CONFIG.PRIMARY_COLOR_KEY}}`).join(`fill="${primaryColorValue}"`)
}

export const swapColorShade = (origin: string, toShade: TColorShade) => {
  const fromColors = toShade === 'light' ? LIGHT_COLORS : DARK_COLORS
  const toColors = toShade === 'light' ? DARK_COLORS : LIGHT_COLORS

  const withNames = replaceColorsWithNames(origin, fromColors)
  const withOtherColors = replaceNamesWithColors(withNames, toColors)

  return withOtherColors
}
