import * as React from 'react'

import { createContext } from '../utils/createContext'

type TDisplayedBoard = typeof BOARD_DISPLAY_OPTIONS[number]

type TContextValue = {
  displayedBoard: TDisplayedBoard
  setDisplayedBoard: (board: TDisplayedBoard) => void
}

const [Provider, useBoardDisplayCtx] = createContext<TContextValue>(`BoardDisplay`)

type TProps = RequiredChildren

export const BoardDisplayProvider: React.FC<TProps> = ({ children }) => {
  const [displayedBoard, setDisplayedBoard] = React.useState<TDisplayedBoard>('both')

  return <Provider value={{ displayedBoard, setDisplayedBoard }}>{children}</Provider>
}

export { useBoardDisplayCtx }

export const BOARD_DISPLAY_OPTIONS = ['light', 'dark', 'both']
