import * as React from 'react'

import { createContext } from '../../utils/createContext'
import { useChangeSelectedPath } from './useChangeSelectedPath'
import { useSelectedPath } from './useSelectedPath'
import { useSvgPaths } from './useSvgPaths'

type TContextValue = {
  pathsCount: number
  selectedPathIndex: Nullable<number>
  pathChangeCount: number
}

type TReconnect = ReturnType<typeof useSvgPaths>['reconnectHandlers']
type TUpdaters = {
  changeSelectedPath: ReturnType<typeof useChangeSelectedPath>
  setPathIndex: ReturnType<typeof useSelectedPath>['setPathIndex']
}

const [Provider, useSelectedElementCtx] = createContext<TContextValue>(`SelectedElement`)
const [ReconnectProvider, useReconnectCtx] = createContext<TReconnect>(`Reconnect`)
const [
  UpdateProvider, //
  useUpdateSelectedElementCtx,
] = createContext<TUpdaters>(`UpdateSelectedElement`)

type TProps = RequiredChildren

export const SelectedElementProvider: React.FC<TProps> = ({ children }) => {
  const { selectedPathIndex, setPathIndex, pathChangeCount } = useSelectedPath()

  const { pathsCount, reconnectHandlers } = useSvgPaths({ setPathIndex })
  const changeSelectedPath = useChangeSelectedPath({ setPathIndex, pathsCount })

  return (
    <ReconnectProvider value={reconnectHandlers}>
      <UpdateProvider value={{ changeSelectedPath, setPathIndex }}>
        <Provider value={{ pathsCount, selectedPathIndex, pathChangeCount }}>{children}</Provider>
      </UpdateProvider>
    </ReconnectProvider>
  )
}

export { useReconnectCtx, useSelectedElementCtx, useUpdateSelectedElementCtx }
