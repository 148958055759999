import * as React from 'react'

import { HexColorInput, HexColorPicker, RgbStringColorPicker } from 'react-colorful'

import { useHistoryCtx } from '../contexts/HistoryProvider'
import { DARK_COLORS, LIGHT_COLORS } from '../contexts/IllustrationColorsProvider'
import { usePrimaryColorCtx } from '../contexts/PrimaryColorProvider'
import { useSelectedColorCtx } from '../contexts/SelectedColorProvider'
import { convertToHex, convertToRgb, isHexColor } from '../utils/colorUtils'
import { objectKeys } from '../utils/object'
import { Button } from './Button'
import styles from './ColorControls.module.css'
import { ControlGroup } from './ControlGroup'

type TSetHexColor = Defined<PropsOf<typeof HexColorPicker>['onChange']>
type TSetRgbColor = Defined<PropsOf<typeof RgbStringColorPicker>['onChange']>

type TProps = NoChildren

export const ColorControls: React.FC<TProps> = () => {
  const [showPrimaryPicker, setShowPrimaryPicker] = React.useState(false)

  const { storeCurrentImage } = useHistoryCtx()

  const { changeColor, selectedColor } = useSelectedColorCtx()
  const { primaryColor, changePrimaryColor } = usePrimaryColorCtx()

  const selectedColorValue = React.useMemo(() => {
    return isHexColor(selectedColor) ? selectedColor : ``
  }, [selectedColor])

  const setHexColor = React.useCallback<TSetHexColor>(
    (newColor) => changeColor({ colorValue: newColor }),
    [changeColor],
  )

  const changePrimaryColorValue = React.useCallback<TSetRgbColor>(
    (newColor) => {
      changePrimaryColor(convertToRgb(newColor))

      if (selectedColor === primaryColor) {
        changeColor({ colorValue: convertToRgb(newColor) })
        return
      }

      // it is stored automatically with changeColor
      // but we want to store also the change of the primary color value
      storeCurrentImage()
    },
    [changeColor, changePrimaryColor, primaryColor, selectedColor, storeCurrentImage],
  )

  const title = React.useMemo(() => {
    return selectedColor === primaryColor ? `primary` : selectedColor
  }, [primaryColor, selectedColor])

  return (
    <ControlGroup title={`Color${title ? `: ${title}` : ``}`}>
      {/* CUSTOM COLOR PICKER */}
      <div className={styles.colorPicker}>
        <HexColorPicker color={selectedColorValue} onChange={setHexColor} />
        <HexColorInput prefixed color={selectedColorValue} onChange={setHexColor} />
      </div>

      {/* PRIMARY COLOR BUTTON */}
      <Button
        selected={primaryColor === selectedColor}
        onClick={() => changeColor({ colorValue: primaryColor })}
      >
        <div className={styles.colorExample} style={{ backgroundColor: primaryColor }} />
        primary
        <div
          className={styles.openPicker}
          onClick={(event) => {
            // allows just to open/close the picker without setting the primary color to the path
            event.stopPropagation()
            setShowPrimaryPicker((prevState) => !prevState)
          }}
        >
          <ColorPaletteIcon />
        </div>
      </Button>

      {/* PRIMARY COLOR PICKER */}
      {showPrimaryPicker && (
        <div className={styles.colorPicker}>
          <RgbStringColorPicker color={primaryColor} onChange={changePrimaryColorValue} />
          <HexColorInput
            prefixed
            color={convertToHex(primaryColor)}
            onChange={changePrimaryColorValue}
          />
        </div>
      )}

      {/* CONTRAST COLOR BUTTONS */}
      {objectKeys(DARK_COLORS).map((color) => (
        <Button
          key={color}
          selected={color === selectedColor}
          onClick={() => changeColor({ colorName: color })}
        >
          <div
            title={DARK_COLORS[color]}
            className={styles.colorExample}
            style={{ backgroundColor: DARK_COLORS[color] }}
          />

          {color}

          <div
            title={LIGHT_COLORS[color]}
            className={styles.colorExample}
            style={{ backgroundColor: LIGHT_COLORS[color] }}
          />
        </Button>
      ))}
    </ControlGroup>
  )
}

// * HELPERS

const ColorPaletteIcon: React.FC<NoChildren> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48">
      <path
        d="M24 6c-9.94 0-18 8.06-18 18s8.06 18 18 18c1.66 0 3-1.34 3-3 0-.78-.29-1.48-.78-2.01-.47-.53-.75-1.22-.75-1.99 0-1.66 1.34-3 3-3h3.53c5.52 0 10-4.48 10-10 0-8.84-8.06-16-18-16zm-11 18c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm6-8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm10 0c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm6 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
        fill="currentColor"
      />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}
