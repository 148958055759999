import * as React from 'react'

import { CONFIG } from '../config/config'
import { createContext } from '../utils/createContext'
import { getDarkSvgPaths, getLightSvgPaths } from '../utils/getSvgPaths'
import { isElement } from '../utils/isElement'

type TContextValue = {
  primaryColor: string
  changePrimaryColor: (newValue: string) => void
}

const [
  Provider, //
  usePrimaryColorCtx,
] = createContext<TContextValue>(`PrimaryColor`)

type TProps = RequiredChildren

/**
 * Provides:
 *  - the `primaryColor` value
 *  - the `changePrimaryColor` function that will
 *    change the `primaryColor` value
 *    & also the color value of all paths that already have a primary color
 */
export const PrimaryColorProvider: React.FC<TProps> = ({ children }) => {
  const [primaryColor, setPrimaryColor] = React.useState(CONFIG.PRIMARY_COLOR_INIT_VALUE)

  // change the color of all paths that have a primary color
  const changePrimaryColor = React.useCallback(
    (newValue: string) => {
      const lightPaths = getLightSvgPaths() ?? []
      const darkPaths = getDarkSvgPaths() ?? []

      const isPrimaryColored = (path: ChildNode | undefined): path is Element => {
        return isElement(path) && path.getAttribute(`fill`) === primaryColor
      }

      const primaryColoredLightPaths = lightPaths.filter(isPrimaryColored)
      const primaryColoredDarkPaths = darkPaths.filter(isPrimaryColored)

      primaryColoredLightPaths.forEach((path) => path.setAttribute(`fill`, newValue))
      primaryColoredDarkPaths.forEach((path) => path.setAttribute(`fill`, newValue))

      setPrimaryColor(newValue)
    },
    [primaryColor],
  )

  return <Provider value={{ primaryColor, changePrimaryColor }}>{children}</Provider>
}

export { usePrimaryColorCtx }
