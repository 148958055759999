import * as React from 'react'

import toast from 'react-hot-toast'

import { CONFIG } from '../config/config'
import { useHistoryCtx } from '../contexts/HistoryProvider'
import { DARK_COLORS, LIGHT_COLORS } from '../contexts/IllustrationColorsProvider'
import { usePrimaryColorCtx } from '../contexts/PrimaryColorProvider'
import {
  useReconnectCtx,
  useUpdateSelectedElementCtx,
} from '../contexts/SelectedElementProvider/SelectedElementProvider'
import { useCopyToClip } from '../hooks/useCopyToClip'
import { changeIllustration } from '../utils/changeIllustration'
import {
  replaceColorsWithNames,
  replaceNamesWithColors,
  replacePrimaryColorName,
  replacePrimaryColorValue,
} from '../utils/replaceColors'
import { Button } from './Button'
import { ControlGroup } from './ControlGroup'

type TStyleAttribute = React.HTMLAttributes<HTMLElement>['style']

const SVG_REGEX = /^<svg/ // matches string that starts with the svg html element

const ERROR_TOAST_DURATION = 10 * 1000 //ms
const CODE_SNIPPET_STYLE: TStyleAttribute = { backgroundColor: `whitesmoke` }

type TProps = NoChildren

export const IllustrationControls: React.FC<TProps> = () => {
  const copyToClipboard = useCopyToClip()
  const reconnectHandlers = useReconnectCtx()
  const { clearHistory, storeCurrentImage } = useHistoryCtx()

  const { changeSelectedPath } = useUpdateSelectedElementCtx()
  const { primaryColor } = usePrimaryColorCtx()

  const copyResult = () => {
    // unselect the selected path first
    changeSelectedPath(null)

    // wait to next render, when the previously selected path is no longer marked
    setTimeout(() => {
      const container = document.querySelector(`#${CONFIG.LIGHT_BOARD_ID}`)

      if (!container?.firstElementChild) return

      const svgElement = container.firstElementChild

      if (svgElement instanceof Element) {
        const originalResult = svgElement.outerHTML

        const resultWithColorNames = replaceColorsWithNames(originalResult, DARK_COLORS)
        const transformedResult = replacePrimaryColorValue(resultWithColorNames, primaryColor)

        copyToClipboard({ text: transformedResult })
      }
    })
  }

  const importIllustration = async () => {
    try {
      const input = await navigator.clipboard.readText()

      if (!input.match(SVG_REGEX)) {
        toast.error(`No SVG was found in the clipboard`)
        return
      }

      // replace also the primary color hex value that is usually used in original SVGs
      const inputWithPrimaryColor = replacePrimaryColorValue(input, CONFIG.PRIMARY_COLOR_HEX)
      const transformedInput = replacePrimaryColorName(inputWithPrimaryColor, primaryColor)

      changeIllustration({
        illustration: replaceNamesWithColors(transformedInput, DARK_COLORS),
        containerId: CONFIG.LIGHT_BOARD_ID,
      })
      changeIllustration({
        illustration: replaceNamesWithColors(transformedInput, LIGHT_COLORS),
        containerId: CONFIG.DARK_BOARD_ID,
      })

      reconnectHandlers()
      clearHistory()

      // store the initial state
      storeCurrentImage()

      toast.success(`Imported from clipboard`)
    } catch {
      // Firefox currently doesn't allow web pages to access the clipboard via JavaScript
      // https://stackoverflow.com/questions/73613086/how-to-get-the-content-from-clipboard-on-firefox-in-javascript
      // hence the try-catch with the toast
      toast.error(
        <div>
          <p>Copying from the clipboard is not allowed.</p>
          <p>
            To enable copying from the clipboard, please go to{' '}
            <a href={`about:config`}>about:config</a> and set{' '}
            <code style={CODE_SNIPPET_STYLE}>dom.events.testing.asyncClipboard</code> and{' '}
            <code style={CODE_SNIPPET_STYLE}>dom.events.asyncClipboard.readText</code> to{' '}
            <code style={CODE_SNIPPET_STYLE}>true</code>.
          </p>
        </div>,
        { style: { maxWidth: `fit-content` }, duration: ERROR_TOAST_DURATION },
      )
    }
  }

  return (
    <ControlGroup title={`Illustration`}>
      <Button onClick={importIllustration}>Import from clip</Button>
      <Button selected onClick={copyResult}>
        Copy result
      </Button>
    </ControlGroup>
  )
}
