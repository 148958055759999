import { isElement } from './isElement'

/**
 * sets the blue stroke of the 0.5 width on the `path`
 */
export const markPath = (path: ChildNode | undefined) => {
  if (!isElement(path)) return

  path.setAttribute(`stroke`, `blue`)
  path.setAttribute(`stroke-width`, `0.5`)
}

/**
 * removes the stroke and the stroke-width attributes from the `path`
 */
export const unmarkPath = (path: ChildNode | undefined) => {
  if (!isElement(path)) return

  path.removeAttribute(`stroke`)
  path.removeAttribute(`stroke-width`)
}
