import * as React from 'react'

import styles from './Button.module.css'

type TProps = RequiredChildren & {
  className?: string
  selected?: boolean
  onClick?: React.DOMAttributes<HTMLButtonElement>['onClick']
}

export const Button: React.FC<TProps> = ({
  children,
  className = ``,
  selected = false,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`${styles.button} ${selected ? styles.selected : ``} ${className}`}
    >
      {children}
    </button>
  )
}
