import * as React from 'react'

import { useHistoryCtx } from '../contexts/HistoryProvider'
import { Button } from './Button'
import { ControlGroup } from './ControlGroup'
import styles from './HistoryControls.module.css'

type TProps = NoChildren

/**
 * Undo & Redo buttons
 */
export const HistoryControls: React.FC<TProps> = () => {
  const { undo, redo } = useHistoryCtx()

  return (
    <ControlGroup title={`Edit`}>
      <div className={styles.buttonContainer}>
        <Button onClick={undo}> {`<- undo`}</Button>
        <Button onClick={redo}>{`redo ->`}</Button>
      </div>
    </ControlGroup>
  )
}
