import * as React from 'react'

import {
  useSelectedElementCtx,
  useUpdateSelectedElementCtx,
} from '../contexts/SelectedElementProvider/SelectedElementProvider'
import { ControlGroup } from './ControlGroup'
import styles from './PathControls.module.css'

type TProps = NoChildren

/**
 * Input to show & change the selected path number
 * with total `pathCount` rendered next to it.
 */
export const PathControls: React.FC<TProps> = () => {
  const { pathsCount, selectedPathIndex } = useSelectedElementCtx()
  const { changeSelectedPath } = useUpdateSelectedElementCtx()

  // use the value from the input to compute the newPathIndex and change the selected path
  const selectPath: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = parseInt(event.target.value, 10)
    const newPathIndex = isNaN(inputValue) ? null : inputValue - 1

    return changeSelectedPath(newPathIndex)
  }

  return (
    <ControlGroup title={`Path`}>
      <div className={styles.pathCount}>
        <input
          min={0}
          max={pathsCount}
          type={`number`}
          value={selectedPathIndex !== null ? selectedPathIndex + 1 : ``}
          onChange={selectPath}
        />
        <p>/ {pathsCount}</p>
      </div>
    </ControlGroup>
  )
}
