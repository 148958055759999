import * as React from 'react'

import styles from './ControlGroup.module.css'

type TProps = RequiredChildren & {
  title: string
}

export const ControlGroup: React.FC<TProps> = ({ children, title }) => {
  return (
    <div className={styles.group}>
      <h3>{title}</h3>
      <div className={styles.groupItems}>{children}</div>
    </div>
  )
}
