import * as React from 'react'

import { HexColorInput, HslStringColorPicker } from 'react-colorful'

import { CONFIG } from '../config/config'
import { BgColorProvider } from '../contexts/BgColorProvider'
import { useBoardDisplayCtx } from '../contexts/BoardDisplayProvider'
import { IllustrationColorsProvider } from '../contexts/IllustrationColorsProvider'
import { convertToHex, convertToHsl } from '../utils/colorUtils'
import styles from './IllustrationBoard.module.css'
import { IllustrationContainer } from './IllustrationContainer'

type TProps = NoChildren

export const IllustrationBoard: React.FC<TProps> = () => {
  const { displayedBoard } = useBoardDisplayCtx()

  const [lightBoardColor, setLightBoardColor] = React.useState(CONFIG.LIGHT_BOARD_INIT_COLOR)
  const [darkBoardColor, setDarkBoardColor] = React.useState(CONFIG.DARK_BOARD_INIT_COLOR)

  const lightBoardHidden = displayedBoard === `dark`
  const darkBoardHidden = displayedBoard === `light`

  return (
    <div className={styles.board}>
      <div
        className={styles.boardPart}
        style={{ backgroundColor: lightBoardColor, display: lightBoardHidden ? `none` : undefined }}
      >
        <BgColorProvider bgColor={lightBoardColor}>
          <div className={styles.colorPicker}>
            <HslStringColorPicker color={lightBoardColor} onChange={setLightBoardColor} />
            <HexColorInput
              prefixed
              color={convertToHex(lightBoardColor)}
              onChange={(newColor) => setLightBoardColor(convertToHsl(newColor))}
            />
          </div>

          <IllustrationColorsProvider containerId={CONFIG.LIGHT_BOARD_ID}>
            <IllustrationContainer id={CONFIG.LIGHT_BOARD_ID} />
          </IllustrationColorsProvider>
        </BgColorProvider>
      </div>
      <div
        className={styles.boardPart}
        style={{ backgroundColor: darkBoardColor, display: darkBoardHidden ? `none` : undefined }}
      >
        <BgColorProvider bgColor={darkBoardColor}>
          <div className={styles.colorPicker}>
            <HslStringColorPicker color={darkBoardColor} onChange={setDarkBoardColor} />
            <HexColorInput
              prefixed
              color={convertToHex(darkBoardColor)}
              onChange={(newColor) => setDarkBoardColor(convertToHsl(newColor))}
            />
          </div>

          <IllustrationColorsProvider containerId={CONFIG.DARK_BOARD_ID}>
            <IllustrationContainer id={CONFIG.DARK_BOARD_ID} />
          </IllustrationColorsProvider>
        </BgColorProvider>
      </div>
    </div>
  )
}
