import * as React from 'react'

import {
  BOARD_DISPLAY_OPTIONS,
  useBoardDisplayCtx,
} from '../contexts/BoardDisplayProvider'
import { Button } from './Button'
import { ControlGroup } from './ControlGroup'

type TProps = NoChildren

export const BoardDisplayControls: React.FC<TProps> = () => {
  const { displayedBoard, setDisplayedBoard } = useBoardDisplayCtx()

  return (
    <ControlGroup title={`Board`}>
      {BOARD_DISPLAY_OPTIONS.map((option) => (
        <Button
          key={option}
          onClick={() => setDisplayedBoard(option)}
          selected={option === displayedBoard}
        >
          {option}
        </Button>
      ))}
    </ControlGroup>
  )
}
