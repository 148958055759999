type TMinMaxOptions = {
  min: number
  max: number
}

/**
 * clamp the value into <min, max> interval
 */
export const clampValue =
  ({ min, max }: TMinMaxOptions) =>
  (value: Nullable<number>) => {
    if (value === null || value < min) {
      return min
    }

    if (value > max) {
      return max
    }

    return value
  }
