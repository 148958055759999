import * as React from 'react'

import { getDarkSvgPaths, getLightSvgPaths } from '../../utils/getSvgPaths'
import { isElement } from '../../utils/isElement'
import { objectEntries } from '../../utils/object'
import { useHistoryCtx } from '../HistoryProvider'
import { DARK_COLORS, LIGHT_COLORS, TIllustrationColors } from '../IllustrationColorsProvider'
import { usePrimaryColorCtx } from '../PrimaryColorProvider'
import { useSelectedElementCtx } from './SelectedElementProvider'

type TColorOptions =
  | {
      colorName: keyof TIllustrationColors
      colorValue?: never
    }
  | {
      colorName?: never
      colorValue: string
    }

const NAME_INDEX = 0

/**
 * Takes care of the `selectedColor` of the selected path.
 *
 * Gets the color of the newly selected path.
 * Returns:
 *  - the `changeColor` function to change the color of the selected path
 *  - the `selectedColor` (the color of the selected path)
 */
export const useChangeSelectedColor = () => {
  const [selectedColor, setSelectedColor] = React.useState<Nullable<string>>(null)

  const { primaryColor } = usePrimaryColorCtx()

  const { selectedPathIndex, pathChangeCount } = useSelectedElementCtx()
  const { storeCurrentImage } = useHistoryCtx()

  // if the selected path index changes,
  // get the current path color
  React.useEffect(() => {
    const rawColor = getFillAtIndex(selectedPathIndex)
    const knownColor = objectEntries(DARK_COLORS).find(([, value]) => value === rawColor)

    setSelectedColor(knownColor ? knownColor[NAME_INDEX] : rawColor)
  }, [primaryColor, pathChangeCount, selectedPathIndex])

  // set color of the selected path
  const changeColor = React.useCallback(
    (colorOptions: TColorOptions) => {
      if (selectedPathIndex === null) return

      setFillAtIndex(selectedPathIndex, colorOptions)
      setSelectedColor(colorOptions.colorName ?? colorOptions.colorValue)

      // whenever the color changes, save the current state to history
      storeCurrentImage()
    },
    [selectedPathIndex, storeCurrentImage],
  )

  return { selectedColor, changeColor }
}

// * HELPERS

// get the `fill` value of the path at the provided `index`
const getFillAtIndex = (index: Nullable<number>) => {
  if (index === null) return null

  const lightSvgPaths = getLightSvgPaths()
  const newLightPath = lightSvgPaths?.at(index)

  return isElement(newLightPath) ? newLightPath.getAttribute(`fill`) : null
}

// set the `fill` value of both dark & light paths at the provided `index`
// to the color computed based on the provided `colorName`
const setFillAtIndex = (index: number, colorOptions: TColorOptions) => {
  const lightSvgPaths = getLightSvgPaths()
  const darkSvgPaths = getDarkSvgPaths()

  const lightPath = lightSvgPaths?.at(index)
  const darkPath = darkSvgPaths?.at(index)

  const getColorValue = getColor(colorOptions)

  if (isElement(lightPath)) {
    lightPath.setAttribute(`fill`, getColorValue(DARK_COLORS))
  }

  if (isElement(darkPath)) {
    darkPath.setAttribute(`fill`, getColorValue(LIGHT_COLORS))
  }
}

// returns either the color value from IllustrationColors based on the provided `colorName`
// or the provided `colorValue`
const getColor = (colorOptions: TColorOptions) => (availableColors: TIllustrationColors) => {
  if (colorOptions.colorName) {
    return availableColors[colorOptions.colorName]
  }

  return colorOptions.colorValue
}
