import * as React from 'react'

import { getDarkSvgPaths, getLightSvgPaths } from '../../utils/getSvgPaths'
import { useSelectedPath } from './useSelectedPath'

type TSetPathIndex = ReturnType<typeof useSelectedPath>['setPathIndex']

type TOptions = {
  setPathIndex: TSetPathIndex
}

/**
 * Connects click handlers to all dark & light paths.
 *
 * Returns:
 *  - the `reconnectHandlers` function to reconnect click handlers e.g. if the illustration changes
 *  - the `pathsCount`
 */
export const useSvgPaths = ({ setPathIndex }: TOptions) => {
  const [runCount, setRunCount] = React.useState(0)
  const [pathsCount, setPathsCount] = React.useState(0)

  // set click listeners to paths
  React.useEffect(() => {
    const lightPaths = getLightSvgPaths() ?? []
    const darkPaths = getDarkSvgPaths() ?? []

    setPathsCount(lightPaths.length)

    lightPaths.forEach((path, index) => {
      path.addEventListener(`click`, () => setPathIndex(index))
    })
    darkPaths.forEach((path, index) => {
      path.addEventListener(`click`, () => setPathIndex(index))
    })

    // TODO unsubscribe better
    return () => {
      lightPaths.forEach((path) => {
        path.removeEventListener(`click`, null)
      })
      darkPaths.forEach((path) => {
        path.removeEventListener(`click`, null)
      })
    }
  }, [runCount, setPathIndex])

  // increases the `runCount` to re-run the useEffect above
  const reconnectHandlers = React.useCallback(() => setRunCount((prev) => prev + 1), [])

  return { pathsCount, reconnectHandlers }
}
