import * as React from 'react'

import { createContext } from '../utils/createContext'
import { useChangeSelectedColor } from './SelectedElementProvider/useChangeSelectedColor'

type TContextValue = ReturnType<typeof useChangeSelectedColor>

const [
  Provider, //
  useSelectedColorCtx,
] = createContext<TContextValue>(`SelectedColor`)

type TProps = RequiredChildren & {}

/**
 * Provides:
 *  - the `changeColor` function to change the color of the selected path
 *  - the `selectedColor` (the color of the selected path)
 */
export const SelectedColorProvider: React.FC<TProps> = ({ children }) => {
  const selectedColorContext = useChangeSelectedColor()

  return <Provider value={selectedColorContext}>{children}</Provider>
}

export { useSelectedColorCtx }
